(function (widgets) {
  var Open = widgets.Widget.extend(
    {
      constructor: function AudioConstructor(object) {
        mainNav.nextButton.bind(
          'click keypress',
          $.proxy(next_fraud, window, object)
        );

        Open.__super__.constructor.call(this, object);
        object.PROTECT_TIMEOUT = 5000;
        object.PROTECT_INTERVAL = 100;

        var self = this;

        if (object.dk) {
          var checkboxSelector = '#r-' + object.dk_id;

          // Listen for clicks on the grayed-out input
          this.$el.on('click', '[readonly]', function (e) {
            global.disable_dk_check(object.dk_id);
            self.toggleInputDK(false, object.input_id);
          });

          // Listen for clicks on the DK checkbox
          this.$el.on('click', checkboxSelector, function (e) {
            var dkChecked = $(checkboxSelector)[0].checked;
            self.toggleInputDK(dkChecked, object.input_id);

            $(this).blur().trigger('changed');

            if (dkChecked) {
              disableSpellCheck('#r-' + object.input_id);
            }
          });

          handle_keyboard_checkbox(checkboxSelector);
        }

        // Listen for clicks on legend
        var labelText = 'legend.question-text';

        this.$el.on('click touchend', labelText, function (event) {
          self.focusInput(this, object.input_id, event);
        });
      },
      render: function render() {
        Open.__super__.render.call(this);

        this.injectProtection(this._object);

        this.$el.find('input:checkbox').uniform();

        var NUMERIC_TYPES = [
          'open-int',
          'open-intrange',
          'open-real',
          'open-realrange',
          'zipcode',
        ];

        const maxAfterDecimal = this._object.decimals;
        const currentNumericType = this._object.type;
        const maxBeforeDecimal = parseInt(this._object.max).toString().length;
        var inputWidth = this._object.cols * 15;

        $('#r-' + this._object.input_id).on('input', function () {
          if (
            maxAfterDecimal &&
            maxBeforeDecimal &&
            NUMERIC_TYPES[3] === currentNumericType
          ) {
            this.value = this.value
              .replace(
                new RegExp('(^[\\d]{' + maxBeforeDecimal + '})[\\d]', 'g'),
                '$1'
              )
              .replace(
                new RegExp('([\\.\\,][\\d]{' + maxAfterDecimal + '}).', 'g'),
                '$1'
              );
          }
        });

        if (NUMERIC_TYPES.indexOf(this._object.type) !== -1) {
          if (this._object.hasOwnProperty('max') && this._object.max > 0) {
            var maxlen = '';
            if (!maxAfterDecimal) {
              maxlen =
                typeof this._object.max === 'number'
                  ? this._object.max.toString().length
                  : this._object.max.length;
              if (maxlen < this._object.cols) {
                // set our input's 'maxlength' attribute based
                // on the number (in digits) expected
                $('#r-' + this._object.input_id).attr('maxlength', maxlen);
                inputWidth = maxlen * 15;
              }
            } else {
              maxlen = maxAfterDecimal + maxBeforeDecimal + 1;
              if (maxlen < this._object.cols) {
                inputWidth = maxlen * 15;
              }
            }
          }
        }

        $('#r-' + this._object.input_id).css({
          width: '94%',
          'max-width': inputWidth + 1,
        });
      },
      toggleInputDK: function toggleInputDK(isEnabled, inputId) {
        $('#r-' + inputId)
          .toggleClass('read-only-gray', isEnabled)
          .attr('readonly', isEnabled);

        // Note: Ensures the input is focused after toggling DK
        if (!isEnabled) this.blurFocusInput(inputId);
      },
      injectProtection: function (object) {
        if (object.protect) {
          var eventSilent = function () {};

          var objElement = {
            textElementId: 'r-' + object.input_id,
            pastedTextElementId: 'rd_' + object.input_id + '_copy_paste',
            pageLoadTextTimeElementId:
              'rd_' + object.input_id + '_time_page_load',
            entryStartTextTimeElementId:
              'rd_' + object.input_id + '_time_text_entry',
            submitTextTimeElementId:
              'rd_' + object.input_id + '_time_submit_entry',
            submitButtonElementId: 'next_button',
          };

          var retries = 0;
          var max_retries = object.PROTECT_TIMEOUT / object.PROTECT_INTERVAL;
          var interval = setInterval(function () {
            if (retries > max_retries) {
              console.error('Error loading RD script');
              object.protect = false;
              clearInterval(interval);
            }

            try {
              var scReviewLib = window.scReviewLib;
              if (scReviewLib) {
                scReviewLib(
                  'https://prod.rtymgt.com/',
                  object.api_key, // client’s publishable key on environment
                  objElement, // Object Element defined above
                  eventSilent,
                  eventSilent,
                  eventSilent,
                  eventSilent
                );
                clearInterval(interval);
              }
              retries++;
            } catch (e) {}
          }, object.PROTECT_INTERVAL);
        }
      },
      blurFocusInput: function (inputId) {
        $('#r-' + inputId)
          .blur()
          .focus();
      },
      focusInput: function (object, inputId, event) {
        var inputTextToFocus = $(object)
          .closest('fieldset')
          .find('#r-' + inputId);

        if (!inputTextToFocus) return;

        if (event.type === 'touchend') {
          event.preventDefault();
          event.stopImmediatePropagation();
          inputTextToFocus.focus().trigger('click');
        }
        inputTextToFocus.focus();
      },
    },
    {
      types: [
        'open',
        'open-int',
        'open-intrange',
        'open-real',
        'open-realrange',
        'email',
        'password',
        'zipcode',
      ],
      views: ['open'],
    }
  );

  var next_fraud = function (object, event) {
    var inputText = $('#r-' + object.input_id);
    if (object.protect && inputText.val().length > 0) {
      var pastedData = $('#rd_' + object.input_id + '_copy_paste').val();
      var timePageLoad = $('#rd_' + object.input_id + '_time_page_load').val();
      var timeTextEntry = $(
        '#rd_' + object.input_id + '_time_text_entry'
      ).val();
      var timeSubmitEntry = window.btoa(
        unescape(encodeURIComponent(Date.now()))
      );

      $.ajax({
        type: 'POST',
        url:
          'https://prod.rtymgt.com/api/v4/respondents/review/' + object.api_key,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
          q_id: object.input_id,
          text: inputText.val(),
          c_text: pastedData,
          t_page_load: timePageLoad,
          t_text_typed: timeTextEntry,
          t_submit: timeSubmitEntry,
          sn_ud: object.visa,
          sy_nr: object.survey_id,
        }),
        success: function (data) {
          inputText
            .attr('readonly', true)
            .css({ color: '#eee', 'background-color': '#eee' });
          inputText.val(
            JSON.stringify({
              input: inputText.val(),
              protect_response: data,
            })
          );
          click_next({ key: '', type: 'click' });
        },
        error: function (e) {
          console.error('Error processing RD data', e);
          click_next({ key: '', type: 'click' });
        },
      });
    } else {
      click_next({ key: '', type: 'click' });
    }
  };

  Open.register();
  widgets.Open = Open;
})((Gryphon.widgets = Gryphon.widgets || {}));
