/*
 * Global functions
 */
/* global global */

import { report } from './report';
import __ from '../localization';

// Mock console.log if it doesn't exist (IE).
if (typeof console === 'undefined') {
  global.console = {
    log: function () {},
  };
}

/*
Disabled due to lack of support on backend
$.ajaxSetup({
  beforeSend: function (xhr) {
    xhr.setRequestHeader('Accepts-Version', window.apiVersion);
  },
});
*/

global.audioFinished = function () {
  $(page_state).trigger('audio_finished');
};

global.check_redirect = function (res) {
  if (res.redirect_to) {
    $(window).unbind('beforeunload');
    window.location.href = res.redirect_to;
    return true;
  }
  return false;
};

global.decodeHtmlEntities = function (string) {
  var txt = document.createElement('textarea');
  txt.innerHTML = string;
  return txt.value;
};

global.check_scroll = function (res) {
  if (res.scrollto) {
    window.setTimeout(function () {
      try {
        $.scrollTo('#' + res.scrollto, { offset: -100 });
      } catch (e) {}
    }, 200);
  } else {
    try {
      $.scrollTo(0);
    } catch (e) {}
  }
};

global.injectPartnerUrl = function (res) {
  if (res.partner_tracking_uri) {
    const partner_tracking_uri =
      process.env.BE_DOMAIN + res.partner_tracking_uri;
    if (Array.isArray(page_state._js)) {
      page_state._js.push(partner_tracking_uri);
    }
  }
};

global.check_timing = function (res) {
  if (res.record_timing) {
    page_state.starttime = Gryphon.util.get_page_time();
  }
};

global.contains_value = function (arr, val) {
  try {
    return arr.indexOf(val) != -1;
  } catch (e) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == val) {
        return true;
      }
    }
    return false;
  }
};

global.connect_signal_handlers = function (res) {
  /* jshint evil: true */
  var handlers = page_state.signal_handlers;
  if (handlers) {
    for (var i = 0; i < handlers.length; i++) {
      var signame, handler;
      signame = handlers[i][0];
      handler = handlers[i][1];
      $(page_state).bind(signame, window.eval('func = ' + handler));
    }
  }
};

global.connect_nav_handlers = function (res) {
  if (!res.init_state) {
    return;
  }

  var buttons = [
    {
      button: mainNav.backButton,
      func: click_back,
    },
    {
      button: mainNav.nextButton,
      func: click_next,
    },
    {
      button: mainNav.skipButton,
      func: click_skip,
    },
  ];

  for (var i = 0; i < buttons.length; i++) {
    var btn = buttons[i];
    var handlers = res.init_state[btn.button.id + '_handlers'];
    if (handlers) {
      for (var j = 0; j < handlers.length; j++) {
        var handler = handlers[j];
        btn.button.bind('click keypress', window[handler]);
      }
    } else {
      btn.button.bind('click keypress', btn.func);
    }
    btn.button.enable();
  }

  global.loaded_buttons();
};

global.create_custom_order = function (widget, qtype, order) {
  try {
    var $order = typeof order !== 'object' ? JSON.parse(order) : order;
    var c, element_row, element_id, varname;
    if (qtype == 'multiple' || qtype == 'single') {
      element_row = '.dynamic-row';
      element_id = widget._object.element_id;
      // hide then unhide as necessary
      $(element_row)
        .find("div[id|='" + element_id + "'].question-response-item")
        .hide();
      for (c in $order) {
        if (
          $(element_row)
            .last()
            .find(
              "div[id|='" +
                element_id +
                '-' +
                $order[c] +
                "'].question-response-item"
            )
            .find('input').length == 0
        ) {
          $(
            "div[id|='" +
              element_id +
              '-' +
              $order[c] +
              "'].question-response-item"
          )
            .show()
            .find('input')
            .first()
            .closest(element_row)
            .insertAfter($(element_row).last());
        } else {
          // Does not need re-organizing within this loop
          $(
            "div[id|='" +
              element_id +
              '-' +
              $order[c] +
              "'].question-response-item"
          ).show();
        }
      }
    }
  } catch (e) {
    create_custom_order(widget, qtype, eval(order));
  }
};

global.debug_timing_push = function (event) {
  if (!collect_debug_page_timings) {
    return;
  }
  var perf_now = -1;
  if (window.performance && window.performance.now) {
    perf_now = window.performance.now();
  }
  debug_timings.push([event, page_state.this_page, Date.now(), perf_now]);
};

global.discover_variables = function (res) {
  page_state.allvars = res.variables;
};

global.exit_warning = function () {
  return Gryphon.templates['exit-warning']({
    url: window.location.href.split('?')[0],
  });
};

global._fire_page_timer = function (cid, func, args) {
  if (page_state.page_events[cid] == 1) {
    func.apply(this, args);
  }
};

global.get_form_value = function (vname) {
  var v = document.forms[0][vname];
  if (Gryphon.util.isUndefinedOrNull(v)) {
    return '';
  }

  // some kind of finite, direct value..
  if (
    v.type == 'text' ||
    v.type == 'textarea' ||
    v.type == 'password' ||
    v.type == 'hidden' ||
    v.type == 'select-one' ||
    v.type == 'number'
  ) {
    if (v.value == undefined) {
      return '';
    }
    return v.value;
  }

  // radio list
  if (v.type == undefined && !Gryphon.util.isUndefinedOrNull(v.length)) {
    for (var x = 0; x < v.length; x++) {
      if (v[x].checked == true) {
        return v[x].value;
      }
    }
    return '';
  }

  // single radio item
  if (v.type == 'radio') {
    if ($(v).is(':checked')) {
      return v.value;
    }
    return '';
  }

  // checkbox
  if (v.type == 'checkbox') {
    if (v.checked) {
      if (Gryphon.util.isUndefinedOrNull(v.value)) {
        return '1';
      } else {
        return v.value;
      }
    } else {
      return '';
    }
  }

  // unknown type
  return '';
};

global.handle_keyboard_checkbox = function (selector) {
  $('body').on('keydown', selector, function (e) {
    if (e.keyCode == 13 || e.keyCode == 32) {
      var self = $(this);
      // Only listen for this 'changed' event when 'keydown' event occurs
      self.one('changed', function () {
        self.focus();
      });
    }
  });
};

global.monitor_call = function (func, silent) {
  silent = silent || false;
  try {
    func();
  } catch (err) {
    report.logError(err, silent);
  }
};

global.on_change = function (e) {
  // Click the element, and update it's
  // aria-checked attribute for screen readers
  keyboard_select(e);
  if (e.keyCode == 13 || e.keyCode == 32) {
    switch (e.target.getAttribute('aria-checked')) {
      case 'true':
        e.target.setAttribute('aria-checked', 'false');
        break;
      case 'false':
        e.target.setAttribute('aria-checked', 'true');
        break;
      default:
        break;
    }
  }
};

global.page_timer = function (seconds, func) {
  var args = new Array();
  for (var i = 2; i < arguments.length; i++) {
    args.push(arguments[i]);
  }
  var cid = Gryphon.util.gen_rand_string(30);
  page_state.page_events[cid] = 1;
  window.setTimeout(function () {
    _fire_page_timer(cid, func, args);
  }, seconds * 1000);
};

global.ready = function (callback) {
  $(page_state).on('after_content_loaded', callback);
};

global.keyboard_select = function (e) {
  if (e.keyCode == 13 || e.keyCode == 32) {
    e.target.click();
  }
};

global.scale_select_tab = function (e, name, name_dk, value) {
  if (e.keyCode == 13 || e.keyCode == 32) {
    scale_select(name, name_dk, value);
  }
};

global.scale_select = function (name, name_dk, value) {
  var el, elms;
  el = $('#r-' + name + '-' + value);
  elms = $('.' + name + ' .scale-options .scale_button');
  if (el != null && el.attr('class')) {
    var old_class = el.attr('class');
    el.removeClass();
    elms.attr('class', 'scale_button scale_cell_value');
    el.addClass('scale_button_high');
    el.addClass('scale_cell_value');
    $('.' + name)
      .find('.scale_button')
      .attr('aria-checked', false);
    el.attr('aria-checked', true);
    el.attr('data-value', value);
  }
  var v = $('input[name=' + name + ']');
  v.val(value);
  global.disable_dk_check(name_dk);

  // Special handling for 'hidden-response' elements
  // only remove the class (unhide the selected value)
  // for the current scale (not all scales on the page)
  $('tr.' + name)
    .find('div.scale_cell_value')
    .filter('.scale_button_high')
    .removeClass('hidden-response')
    .end();

  // remove any focus states
  elms.find('*').blur();
};

global.disable_dk_check = function (name_dk) {
  // If we've provided a 'name' for the DK input, we should
  // use it to target the specific DK input on the page
  // as there could be multiple instances
  var $name = '';
  if (name_dk) {
    $name = '[name="' + name_dk + '"]';
  }
  if ($('.dk-check:visible' + $name).prop('checked')) {
    $('.dk-check:visible' + $name).prop('checked', false);
    $('.dk-check:visible' + $name)
      .closest('.dk-input')
      .find('.uniform-checked')
      .removeClass('uniform-checked');
    if (!name_dk) {
      // Note: this could cause undesirable effects if there
      // are multiple questions on one page with hidden responses
      // because of previously selected DK values
      $('.hidden-response:visible').removeClass('hidden-response');
    }
    $('.hidden-check').removeClass('hidden-check');
  }
};

global.set_language_direction = function (rtl) {
  var html = $('html');

  if (rtl) {
    html.attr('dir', 'rtl');
  } else {
    html.attr('dir', 'ltr');
  }
};

global.set_page_name_visibility = function (res) {
  if (res.show_page_name) {
    $('#div_page_name_api')
      .text(
        'Page: ' +
          (res.init_state && res.init_state.this_page
            ? res.init_state.this_page
            : 'Undefined')
      )
      .show();
  } else {
    $('#div_page_name_api').hide();
  }
};

global.set_fraud_tracking = function (res) {
  if (res?.fraud) {
    var { rd_signed_url } = res.fraud;
    try {
      $.ajax({
        type: 'GET',
        url: `${rd_signed_url}`,
        headers: {
          'Content-Type': 'application/json',
        },
        global: false,
      })
        .done(function (response) {
          if (response && typeof response === 'object') {
            page_state.vardata['__fraud_response__'] = JSON.stringify(response);
          } else {
            page_state.vardata['__fraud_response__'] = 'SCRIPT_LOADING_ERROR';
          }
        })
        .fail(function () {
          page_state.vardata['__fraud_response__'] = 'SCRIPT_LOADING_ERROR';
        });
    } catch (e) {
      page_state.vardata['__fraud_response__'] = 'SCRIPT_LOADING_ERROR';
    }
  }
};

global.set_footer_text = function (res) {
  if (res.footer !== undefined) {
    $('.footer-text').html(res.footer).show();
  }
};

global.set_nav_button_visibility = function (res) {
  var back = mainNav.backButton;
  var next = mainNav.nextButton;

  // FIXME: There is some legacy JS that will send an Object with
  // 'next_button' and 'back_button' as keys, hence the reason why we need to
  // bend ourselves backwards to check for that possibillity here (see
  // `dyngrid.js`)

  // Configure back button.
  if (res[back.id] === undefined) {
    back.hide();
  } else if (res[back.id]) {
    back.show();
  } else if (Object.prototype.hasOwnProperty.call(res, 'back_button')) {
    if (res['back_button']) {
      back.show();
    } else {
      back.hide();
    }
    if (res.no_back_nav === true) {
      back.prop('no-back-nav');
    }
  } else {
    back.hide();
  }

  // Configure next button.
  if (res[next.id] === undefined) {
    next.hide();
  } else if (res[next.id]) {
    next.show();
  } else if (Object.prototype.hasOwnProperty.call(res, 'next_button')) {
    if (res['next_button']) {
      next.show();
    } else {
      next.hide();
    }
  } else {
    next.hide();
  }

  if (res.hide_nav === true) {
    mainNav.hide();
  } else {
    mainNav.show();
  }
};

global.set_query_params = function (data) {
  const qs = new URLSearchParams(window.location.search.replace('?', ''));
  const allowedKeys = ['session_settings_id'];
  const filtered = new URLSearchParams();

  for (const key of allowedKeys) {
    // For now, we only care about appending
    // query string to non-submitting POSTs
    if (qs.has(key) && '__submit__' in data === false) {
      filtered.append(key, qs.get(key));
    }
  }

  return filtered.toString() ? `?${filtered.toString()}` : '';
};

global.set_spd_elements_state = function (res) {
  /**
   * Display SPD (GDPR) consent content
   */
  if (res.is_spd_question) {
    if (res.show_consent_text) {
      $('.spd-consent-text p').text(res.consent_text);
      $('.spd-content').show();
      page_state['spd_consent_text_shown'] = true;
    }
    $('a.spd-consent-skip').text(res.skip_text);
    mainNav.skipButton.show();
  }
};

global.uncheck_array = function (element_ids) {
  for (var i = 0; i < element_ids.length; i++) {
    var el = $('#' + element_ids[i]);
    if (el && el.is(':checked')) {
      el.prop('checked', false);
      $.uniform.update('#' + element_ids[i]);
    }
  }
  var dropdown = $('.question-dropdown .question-open-ended-input select');
  if (dropdown.length > 0) {
    var isChecked = $.makeArray($('.question-multiple .dynamic-row')).some(
      function (element) {
        return $(element).find('.uniform-checker input').is(':checked');
      }
    );
    dropdown.prop('disabled', isChecked);
    var selectedText = dropdown.find('option:selected').text();
    if (isChecked && selectedText.length > 0) {
      dropdown.removeClass('selected');
    } else if (selectedText.length > 0) {
      dropdown.addClass('selected');
    }
  }
};

global.update_live_text = function (announcement) {
  // code to update text to our aria-live span located
  // within the base template
};

global.update_pbar = function (res) {
  if (res.progress) {
    $('#prog_bar').show();
    var progress = (res.progress / 100) * 100;
    $('#pbar_in').css('display', 'block');
    $('#pbar_in').css('width', (res.progress / 100) * 100 + '%');
    //$("#pb_amt").text(res.progress + '');
    $('#prog_bar .bar').css('width', progress + '%');
  } else {
    $('#pbar_cont').addClass('disabled');
  }
};

global.validate_twitter_username = function (username) {
  var url = '/_validate/twitter/' + encodeURI(username);
  return $.getJSON(url).fail(function (e) {
    if (e.message) {
      if (window.newrelic) {
        window.newrelic.noticeError(new Error(e.message));
      }
    }
  });
};

global.videoFinished = function () {
  $(page_state).trigger('video_finished');
};

global.word_wrap = function (l, size, br, sep) {
  var start = $.trim(l);
  var stack = '';
  var c = 0;

  // for special punctuation/characters, we wait for a space to break
  var wait_for_space = false;

  for (var i = 0; i < start.length; i++) {
    var character = start.substring(i, i + 1);
    switch (character) {
      case sep:
        stack += br;
        c = 0;
        break;
      case ' ':
        if (size - c < 4) {
          stack += character;
          c = 0;
          wait_for_space = false;
        } else {
          stack += character;
          c++;
        }
        break;
      default:
        if (c > size) {
          if (!/\W/.test(character) && !wait_for_space) {
            stack += br;
            c = 0;
          } else {
            wait_for_space = true;
          }
        }
        stack += character;
        c++;
        break;
    }
  }

  return stack;
};

global.removeFocusBorderSelect = function () {
  var opened = false;
  var selectElm = $('select');
  selectElm.on('click change blur', function (evt) {
    switch (evt.type) {
      case 'blur':
        opened = false;
        $(this).removeClass('nofocus');
        break;
      case 'click':
        opened = $(this).hasClass('nofocus') ? true : !opened;
        if (opened) {
          if (!$(this).hasClass('nofocus')) {
            $(this).addClass('nofocus');
          }
        } else {
          $(this).removeClass('nofocus');
        }
        break;
      case 'change':
        opened = false;
        $(this).removeClass('nofocus').blur();
        break;
    }
  });
};

global.injectDeviceBrowser = function () {
  document.documentElement.classList.add(device(), OS(), browser());

  function device() {
    let deviceType = 'Unknown';
    if (
      agentHas('Tablet') ||
      agentHas('Tablet PC') ||
      agentHas('iPad') ||
      agentHas('Kindle') ||
      agentHas('Nexus') ||
      (agentHas('Android') && !agentHas('Mobi'))
    ) {
      deviceType = 'Tablet';
    } else if (
      agentHas('Mobi') ||
      agentHas('iPhone') ||
      agentHas('iPod') ||
      agentHas('Android')
    ) {
      deviceType = 'Mobile';
    } else {
      deviceType = 'Desktop';
    }

    return deviceType.toLowerCase();
  }

  function OS() {
    let OSName = 'Unknown-OS';
    if (agentHas('Win')) {
      OSName = 'Windows';
    } else if (agentHas('Macintosh')) {
      OSName = 'MacOs';
    } else if (agentHas('Linux') && !agentHas('Android')) {
      OSName = 'Linux';
    } else if (agentHas('Android') && !agentHas('like Android')) {
      OSName = 'Android';
    } else if (agentHas('like Mac')) {
      OSName = 'iOS';
    }

    return OSName.toLowerCase();
  }

  function browser() {
    let browserName = navigator.appName;

    if (
      agentHas('CriOS') ||
      agentHas('CrOS') ||
      agentHas('Crmo') ||
      agentHas('Chromium') ||
      agentHas('Chrome') ||
      !!window.chrome
    ) {
      browserName = 'Chrome';
    } else if (
      (!!window.ApplePaySetupFeature || !!window.safari) &&
      (agentHas('Safari') || agentHas('Applewebkit')) &&
      !agentHas('Chrome') &&
      !agentHas('CriOS')
    ) {
      browserName = 'Safari';
    } else if (
      agentHas('Firefox') ||
      agentHas('FxiOS') ||
      agentHas('Iceweasel') ||
      agentHas('Focus')
    ) {
      browserName = 'Firefox';
    } else if (agentHas('Opera') || agentHas('Opr') || agentHas('OpiOS')) {
      browserName = 'Opera';
    } else if (agentHas('Msie') || agentHas('Trident')) {
      browserName = 'IE';
    } else if (
      agentHas('Edge') ||
      agentHas('EdgiOS') ||
      agentHas('Edga') ||
      agentHas('Edg')
    ) {
      browserName = 'Edge';
    }

    return browserName.toLowerCase();
  }

  function agentHas(keyword) {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
  }
};

injectDeviceBrowser();

// Removal of red lines under spelling errors cannot be consistently enforced for editable elements (or form fields),
// on all browsers/platforms, when those elements are blurred - regardless of the spellcheck attributes value being set to "false".
// In case we update the innerHTML of the element, the spellcheck will be re-evaluated
// Reasigning the value fixes this with the below solution
global.disableSpellCheck = function (id) {
  const inputFieldId = $(id);
  const valueinputFieldId = inputFieldId.val();
  inputFieldId.val('');
  inputFieldId.val(valueinputFieldId);
};

global.display_feedback_widget = function (res) {
  if (res?.display_feedback_widget && !isNaN(window.hotjarId)) {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: window.hotjarId, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
};

global.set_input_error_text = function () {
  const errorMsgKey = 'Error';
  const errorMsgTranslated = __.localize(errorMsgKey);
  $('#error_input_id').html(errorMsgTranslated);
};

global.cofigCatFeatureFlags = (function () {
  var featureFlags;

  function setFeatureFlags(value) {
    featureFlags = value;
  }

  function getFeatureFlag(key) {
    return featureFlags[key];
  }

  function getAllFeatureFlags() {
    return featureFlags;
  }

  return {
    set: setFeatureFlags,
    get: getFeatureFlag,
    getAll: getAllFeatureFlags,
  };
})();

global.shieldMonitoring = function () {
  const enabled = global.cofigCatFeatureFlags.get('fe_shield') || false;
  if (
    typeof window.shield !== 'undefined' &&
    enabled &&
    !shieldRecorded &&
    !isDeviceDataInProgress
  ) {
    try {
      deviceData();
    } catch (e) {
      if (typeof e == 'string' && e.includes('module not initialised')) {
        shield
          .init(shieldProps)
          .then(function () {
            deviceData();
          })
          .catch(function (err) {
            if (window.newrelic) {
              var errorMessage = 'SHIELD init Error';
              window.newrelic.noticeError(new Error(errorMessage), {
                errorMessage: errorMessage,
                response: err,
              });
            }
          });
      }
    }
  }
};

const pageLoadAndDelayPromise = new Promise((resolve) => {
  const onPageLoad = () => setTimeout(resolve, 15000);
  if (document.readyState === 'complete') {
    onPageLoad();
  } else {
    window.addEventListener('load', onPageLoad);
  }
});

async function deviceData() {
  isDeviceDataInProgress = true;
  try {
    await pageLoadAndDelayPromise;
    let sessionId;
    try {
      const response = await shield.getDeviceIntelligence();
      sessionId = response.result.session_id;
    } catch (err) {
      if (window.newrelic) {
        const errorMessage = 'SHIELD deviceIntelligence Error';
        window.newrelic.noticeError(new Error(errorMessage), {
          errorMessage,
          response: err,
        });
      }
      throw err;
    }
    try {
      if (window.segments && sessionId) {
        const url = `${process.env.API_BASE_URL}shield/${window.segments}/${sessionId}`;
        await $.ajax({
          url,
          method: 'PUT',
          xhrFields: { withCredentials: true },
          global: false,
        });
      } else {
        throw new Error('Missing segments or sessionId');
      }
    } catch (err) {
      if (window.newrelic) {
        const errorMessage = 'SHIELD session_id to Gryphon Error';
        window.newrelic.noticeError(new Error(errorMessage), {
          errorMessage,
          response: err,
        });
      }
      throw err;
    }
    shieldRecorded = true;
    isDeviceDataInProgress = false;
  } catch (err) {
    setTimeout(deviceData, 30000);
  } finally {
  }
}
