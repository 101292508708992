import { Shield } from 'shield-js-npm';

const JS_ASSETS_LOAD_TIMEOUT = 3000;

/* global Modernizr */
Gryphon.util = {
  // jscs:disable maximumLineLength
  /**
   * Load extracss stylesheets. Returns a `jQuery.Deferred().promise()`.
   * If the browser supports the `load` event on link elements, then the
   * promise will only be resolved once all stylesheets have loaded.
   * Otherwise, the promise is resolved immediately.
   * See http://pieisgood.org/test/script-link-events/ for support matrix.
   * If we ever need a more robust solution, it might be worth reimplementing:
   * https://web.archive.org/web/20120831104346/http://yearofmoo.com/2011/03/cross-browser-stylesheet-preloading
   */
  // jscs:enable maximumLineLength
  add_css: function () {
    if (page_state._css) {
      var deferreds = [];
      for (var key in page_state._css) {
        if (
          !key.match(/^\/404\/$/) &&
          !Gryphon.util.isCSSLoaded(page_state._css[key])
        ) {
          deferreds.push(Gryphon.util.import_css(page_state._css[key]));
        }
      }

      return $.when.apply($, deferreds);
    }

    // If no CSS to load, make sure we still return a promise.
    return new $.Deferred().resolve().promise();
  },

  /**
   * Load extrajs scripts. Returns a `jQuery.Deferred().promise()` which
   * is resolved once all scripts have loaded.
   */
  add_js: function () {
    return Gryphon.util.getScript(page_state._js);
  },

  /**
   * Asyncronously load any number of javascript files.
   * Returns a `jQuery.Deferred().promise()`, allowing for callbacks to be
   * attached with .then(). A callback can also be passed as the second
   * parameter.
   * @param {string|Array} urls URLs of the scripts to load.
   * @param {function} [callback] Called when all scripts are loaded.
   */
  getScript: function (urls, callback) {
    var masterDeferred = new $.Deferred();

    if ($.isFunction(callback)) {
      masterDeferred.then(callback);
    }

    if (!urls || !urls.length) {
      return masterDeferred.resolve().promise();
    }

    // Ensure `urls` is always an array.
    if (!$.isArray(urls)) {
      urls = [urls];
    }

    var deferreds = [];

    // Queue up all the jobs.
    for (var i = 0, len = urls.length; i < len; i++) {
      if (!urls[i].match(/^\/404\/$/)) {
        deferreds.push(Gryphon.util.import_js(urls[i]));
      }
    }

    $.when
      .apply($, deferreds)
      .then(masterDeferred.resolve, masterDeferred.reject);

    return masterDeferred.promise();
  },

  /**
   * Low-level function for loading CSS.
   * Note it is NOT idempotent like import_js is.
   */
  import_css: function (css_url) {
    var deferred = new $.Deferred();
    var css = document.createElement('link');
    var body = document.getElementsByTagName('body')[0];
    css.setAttribute('rel', 'stylesheet');
    css.setAttribute('type', 'text/css');
    css.setAttribute('href', css_url);
    body.appendChild(css);

    function resolveDeferred() {
      deferred.resolve();
    }

    if (Modernizr.linkonload) {
      css.onload = resolveDeferred;
    } else {
      resolveDeferred();
    }

    return deferred.promise();
  },

  /**
   * Low-level function for loading an individual script file exactly once.
   * Subsequent calls to with the same URL return the already resolved promise
   * of the first call, without actually loading the script again.
   * Therefore, it's safe to call this function without checking if the file
   * has already been loaded.
   * See below for the paramters and return type.
   */
  import_js: (function () {
    var cache = {};

    /**
     * The real import_js.
     * @param {string} url URL of the script file to load.
     * @returns {object} A `jQuery.Deferred().promise()`.
     */
    return function import_js(url) {
      var deferred;

      if (!cache[url]) {
        deferred = new $.Deferred();

        const script = document.createElement('script');

        script.src = url;

        document.head.appendChild(script);

        // If the script hasn't loaded after `ajax_timeout`,
        // reject the deferred. This is our only means of detecting
        // an error, and is similar to RequireJS's approach.
        var timeout = setTimeout(function () {
          deferred.reject(url);
        }, JS_ASSETS_LOAD_TIMEOUT);

        script.onload = function () {
          deferred.resolve(url);
          clearTimeout(timeout);
        };

        cache[url] = deferred.promise();
      }

      return cache[url];
    };
  })(),

  /**
   * Legacy method to import widget-specific scripts. To be removed
   * once all widgets are ClientRenderable.
   */
  do_imports: function () {
    return Gryphon.util.getScript(page_state.imports || []);
  },

  has_flash: function () {
    /* global ActiveXObject:true */
    try {
      var fo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
      if (fo) {
        return true;
      }
    } catch (e) {
      if (navigator.mimeTypes['application/x-shockwave-flash'] !== undefined) {
        return true;
      }
    }
    return false;
  },

  isTouchDevice: function () {
    var userAgent = navigator.userAgent;
    var devices =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return devices.test(userAgent) || 'ontouchstart' in window;
  },

  get_client_caps: function () {
    return {
      screen_width: screen.width,
      screen_height: screen.height,
      window_height: $(window).height(),
      window_width: $(window).width(),
      flash: Gryphon.util.has_flash(),
      video: Modernizr.video.valueOf(),
      audio: Modernizr.audio.valueOf(),
      canvas: Modernizr.canvas.valueOf(),
      svg: Modernizr.svg.valueOf(),
      touch: Gryphon.util.isTouchDevice(),
    };
  },

  isIE: function () {
    return navigator.userAgent.indexOf('MSIE') != -1;
  },

  IE_do_not_cache: function (config) {
    if (Gryphon.util.isIE()) {
      config.data.__ts__ = new Date().getTime();
    }
  },

  // Helper (try/catch wrap) used because querySelector is brittle
  // and breaks when passed an invalid value (such as quotes)
  isCSSLoaded: function (link) {
    try {
      return document.querySelector(`link[href*='${link}']`);
    } catch (e) {
      return false;
    }
  },

  Firefox_include_content_length: function (config) {
    // FireFox 3.0 (mozilla 1.9.0), will
    // not send the content-length header with a post,
    // causing CherryPy 3.2 to return a 411.  Make `data` an empty JSON
    // value that Gryphon will ignore.
    if (
      config.type == 'POST' &&
      $.isEmptyObject(config.data) &&
      $.browser.mozilla &&
      $.browser.version < '1.9.1'
    ) {
      config.data = '{}';
      config.headers = config.headers || {};
      config.headers['Content-Type'] = 'application/json';
    }
  },

  isUndefinedOrNull: function () {
    for (var i = 0, len = arguments.length; i < len; i++) {
      var o = arguments[i];
      if (!(typeof o === 'undefined' || o === null)) {
        return null;
      }
    }
    return true;
  },

  noop: function () {
    return null;
  },

  remove_array_value: function (arr, val) {
    for (var i = 0, len = arr.length; i < len; i++) {
      if (arr[i] == val) {
        arr.splice(i, 1);
      }
    }
  },

  isFloat: function (n) {
    if (typeof n === 'string') {
      return /\./g.test(n);
    } else {
      return Number(n) === n && n % 1 !== 0;
    }
  },

  /**
   * Given a Number, will return the number of decimal places
   * Ex. precision(59.99) => 2
   */
  precision: function (number) {
    if (!isFinite(number)) {
      return 0;
    }
    var e = 1,
      p = 0;
    while (Math.round(number * e) / e !== number) {
      e *= 10;
      p++;
    }
    return p;
  },

  /**
   * Given a Number and decimal place value
   * round the Number to that decimal place
   * Ex. round(43.2903, 2) => 43.29
   */
  round: function (value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  },

  set_segments: function () {
    segments = window.location.pathname.replace('/', '');
    if (/preview/.test(window.location.pathname)) {
      segments = segments.replace(/\?.*/, '');
    }
    return segments;
  },

  set_query_params: function () {
    var allQueryParams = new URLSearchParams(
      window.location.search.replace('?', '')
    );

    var allowedParams = ['icecat'];
    var filteredParams = new URLSearchParams();

    allowedParams.forEach(function (param) {
      if (allQueryParams.has(param)) {
        filteredParams.append(param, allQueryParams.get(param));
      }
    });

    return filteredParams.toString() ? `?${filteredParams.toString()}` : '';
  },

  get_page_time: function () {
    if (window.performance && window.performance.now) {
      page_timing_source = 'performance';
      return window.performance.now();
    } else {
      return Date.now();
    }
  },

  gen_rand_string: function (l) {
    var output = '';
    for (var i = 0; i < l; i++) {
      var chr = parseInt(Math.random() * 26) + 65;
      output += String.fromCharCode(chr);
    }
    return output;
  },

  /**
   * The parseFromString() method of the DOMParser interface parses a string
   * containing either HTML or XML, returning an HTMLDocument or an XMLDocument
   * Ex. "This &amp; that" returns "This & that"
   */
  decode_string: function (str) {
    var parser = new DOMParser();
    var dom = parser.parseFromString(str, 'text/html');
    return dom.body.textContent;
  },

  get_runtime_vars: function () {
    try {
      // Get the current vars
      $.ajax({
        url: process.env.API_BASE_URL + 'q/' + segments,
        type: 'get',
        data: false,
        traditional: true,
        timeout: window.ajax_timeout,
        success: injectRuntimeParameters,
        error: error_alert,
      });
    } catch (e) {}
  },

  init_shield: function () {
    if (process.env.SHIELD_SITE_ID !== null) {
      window.shield = new Shield();
      // Initialize props to pass the site id and customize the JS url endpoint
      window.shieldProps = {
        siteId: process.env.SHIELD_SITE_ID,
      };
      shield.init(shieldProps).catch(function (err) {
        if (window.newrelic) {
          var errorMessage = 'SHIELD init Error';
          window.newrelic.noticeError(new Error(errorMessage), {
            errorMessage: errorMessage,
            response: err,
          });
        }
      });
    }
  },

  promise_with_timeout: function (promise, timeout = 2000) {
    return Promise.race([
      promise,
      new Promise(function (_, reject) {
        setTimeout(function () {
          reject(new Error('Timeout exceeded'));
        }, timeout);
      }),
    ]);
  },
};

function injectRuntimeParameters(res) {
  const { language, rtl } = res;
  if (language) {
    document.documentElement.setAttribute('lang', language);
    document.documentElement.setAttribute('xml:lang', language);
  }
  if (rtl) {
    document.documentElement.setAttribute('dir', 'rtl');
  } else {
    document.documentElement.setAttribute('dir', 'ltr');
  }
}
