/*
 * Set globals here instead of implicitly relying on `window`
 * so that Webpack can setup our dependency graph
 * ex. global.page_state = {}
 *
 */
global.mainNav = null;
global.segments = null;
global.shieldRecorded = false;
global.isDeviceDataInProgress = false;
global.page_timing_source = 'date';
global.nextQuestionStartTime = null;
global.hide_these = { 'trailing-popup': null };
global.collect_debug_page_timings = false;
global.debug_timings = [];
global.NODE_ENV = process.env.NODE_ENV;
global.EVENTLOG_URL = process.env.EVENTLOG_URL || '';
global.Modernizr = window.Modernizr || {
  audio: true,
  canvas: true,
  fontface: true,
  inlinesvg: true,
  svg: true,
  video: true,
};
import './lib/globals';
